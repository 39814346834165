@use '@angular/material' as mat;
@import '../../../../node_modules/@angular/material/theming';

$dark-app-theme: mat.define-dark-theme(
  (
    color: (
      primary: mat.define-palette(mat.$grey-palette, 700, 300, 900),
      accent: mat.define-palette(mat.$blue-grey-palette, 400),
      warn: mat.define-palette(mat.$red-palette),
    ),
  )
);

/**
  For dark mode include only the color mixin to avoid CSS duplication.
  https://github.com/angular/components/blob/master/guides/duplicate-theming-styles.md
 */
.dark {
  @include mat.all-legacy-component-colors($dark-app-theme);
}

.dark .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #fff;
}

$button-height: 40px;
$icon-height: 24px;
$small-button-height: $button-height * 0.8;
$small-icon-size: $icon-height * 0.8;

button.mat-icon-button {
  &.button-sm {
    width: $small-button-height;
    height: $small-button-height;
    line-height: $small-button-height;

    mat-icon {
      line-height: $small-icon-size;
      height: $small-icon-size;
      width: $small-icon-size;
      font-size: $small-icon-size;
    }
  }
}
// Disable outline on focsed buttons
button:focus {
  outline: none;
}

.mat-icon {
  margin: 0 !important;
}

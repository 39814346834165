@layer responsive {
  mat-icon {
    &.icon-sm {
      height: 17px;
      width: 17px;
      font-size: 17px;
    }
    &.icon-md {
      height: 24px;
      width: 24px;
      font-size: 24px;
    }
    &.icon-lg {
      height: 31px;
      width: 31px;
      font-size: 31px;
    }
  }
}

$modal-icon-size: 30px;

//Snackbars
.snackbar-success {
  @apply bg-successLight-500 text-white;
  button {
    @apply text-gray-800;
  }
}
.snackbar-error {
  @apply bg-warnLight-500 text-white;
  button {
    @apply text-gray-800;
  }
}
//Modals (dialogs)
cp-cus-success-modal,
cp-cus-error-modal {
  .dialog-header {
    padding: 24px 24px 10px;

    mat-icon {
      width: $modal-icon-size;
      height: $modal-icon-size;
      font-size: $modal-icon-size;
    }
  }
}

//View container sets some standard with for elements that should not be larger than
//certain width.
.view-container {
  @apply lg:w-3/4 2xl:w-1/2 mx-auto p-2;
}
//Screen container that sets max width on different breakpoints. Added here so we don't have to import
//full Tailwind components section that might interfere with our styles.
.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @screen sm {
    max-width: 640px;
  }
  @screen md {
    max-width: 768px;
  }
  @screen lg {
    max-width: 1024px;
  }
  @screen xl {
    max-width: 1280px;
  }
  @screen 2xl {
    max-width: 1536px;
  }
}

//Dialogs - make dialogs the same width regardless of content
mat-dialog-container {
  width: 95vw !important;
}

.mat-mdc-dialog-content {
  color: #000 !important;
  font-weight: 500 !important;
}

@screen md {
  mat-dialog-container {
    width: 35vw !important;
  }
}
@screen xl {
  mat-dialog-container {
    width: 25vw !important;
  }
}

.full-width-dialog {
  mat-dialog-container {
    width: 80vw !important;
  }
}

.hm-success-dialog {
  align-items: center;

  // Override material icon styles (icon would not scale up)
  .hm-icon-width {
    width: 36px !important;
    height: 36px !important;
  }
}

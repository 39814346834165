//Format text to look like a link
.link {
  color: #4249cb;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.no-truncate-tooltip {
  max-width: unset !important;
}

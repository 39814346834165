.select-wrapper {
  .mat-form-field {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        .mat-form-field-infix {
          border: 0 !important;
          width: auto !important;
        }
      }
    }
  }
}

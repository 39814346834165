//Background color settings - some @pages require setting the background color
//manually, for example if they are covering other elements and the background
//cannot be transparent.
.background {
  @apply bg-gray-50 dark:bg-gray-A400;
}

.card-background {
  @apply bg-white dark:bg-gray-800;
}

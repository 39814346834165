body {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  font-family: 'Poppins', Helvetica, sans-serif;
}
app-root {
  display: flex;
  height: 100vh;
  flex-direction: column;
}
svg {
  display: initial;
}

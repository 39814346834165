//List style that displays number followed by parenthesis e.g. 1), 10)...
ol.numbered {
  counter-reset: list;
  & > li {
    list-style: none;
  }
  & > li:before {
    content: counter(list) ')';
    counter-increment: list;
    text-align: right;
    width: 2rem;
    padding-right: 0.4rem;
    display: inline-block;
  }
}

.bullet:before {
  content: '\2022';
  margin-right: 0.5em;
}

@media print {
  .print\: {
    &no-break {
      break-inside: avoid;
    }

    &hide {
      display: none !important;
    }
    &block {
      display: block !important;
    }
  }
}
